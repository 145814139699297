<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Price Changed Order List</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form>
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="order_id">Order ID</label>
                                                <input type="text" id="order_id" v-model="search.order_id" class="form-control" placeholder="Enter Order ID" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="merchant_id">Merchant</label>
                                                <v-select name="merchant_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div> 
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-12 summary">
                                        <p class="mr-10">Total Order : <b>{{ summary ? summary.total_order : 0 }} Pcs,</b></p>
                                        <p class="mr-10">Previous Price: <b>{{ summary ? summary.prev_price : 0 }} Tk</b>, New Price: <b>{{ summary ? summary.new_price : 0 }} Tk</b></p>
                                        <p class="mr-10">Price Gap: <b>{{ summary ? summary.price_gap : 0 }} Tk</b> </p>
                                        <p class="ml-20">
                                            <slot v-if="loading">
                                                <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                                            </slot>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loading">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>OrderID</th>
                                                    <th>Merchant</th>
                                                    <th>Previous Price</th>
                                                    <th>New Price</th>
                                                    <th>Date</th>
                                                    <th>Created By</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>OrderID</th>
                                                    <th>Merchant</th>
                                                    <th>Previous Price</th>
                                                    <th>New Price</th>
                                                    <th>Date</th>
                                                    <th>Created By</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ item.order_id }}</td>
                                                    <td>{{ item.merchant ? item.merchant.business : '' }}</td>
                                                    <td>{{ item.prev_price }}</td>
                                                    <td>{{ item.new_price }}</td>
                                                    <td>{{ item.date | dateformat }}</td>
                                                    <td>{{ item.user ? item.user.name : '' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>           
                                    </div>             
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
import config from '@/config'
import moment from 'moment'
export default {
    name:'PriceChanged',
    data(){
        return {
            loading: false,
            search:{
                order_id  : '',
                merchant_id: '',
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            },
            summary: null,
            key: '',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadSummary()
        this.loadData()
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadSummary()
                this.loadData()
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        merchantList () {
            return this.$store.state.commonObj.merchantList
        },
        authUser () {
            return this.$store.state.authUser
        }
    },
    methods:{
        searchData () {
            this.loadSummary()
            this.loadData()
        },
        async loadSummary () {
            this.loading = true
            const params = this.search
            const response = await config.getData('/price-changed/summary', params)
            this.loading = false
            this.summary = response.summary          
        },
        async loadData(){     
            this.loading = true 
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
            const response = await config.getData('/price-changed/list', params)
            this.loading = false
            if (response.success){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        }
    }
}
</script>